import React from "react";
import { Amplify } from "aws-amplify";
import { defaultStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./pages/Home";
import WealthVistaSolution from "./pages/WealthVistaSolution";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { useAuth } from "./provider/AuthProvider";
import TermsOfUse from "./pages/TermsOfUse";
import Privacy from "./pages/Privacy";
import { useHeader } from "./provider/HeaderProvider";

import aws_exports from "./aws-exports";
import ProtectedRoute from "./routes/ProtectedRoute";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "./routes";

Amplify.configure(aws_exports);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

export function App() {
  const { isSignedIn } = useAuth();
  const { hideHeader } = useHeader();

  return (
    <Router>
      <div className="flex min-h-screen flex-col">
        <Toaster />
        {!hideHeader ? <Header /> : null}
        <div className="flex-grow">
          <Routes>
            <Route path={PUBLIC_ROUTES.HOME} element={<Home />} />
            <Route path={PUBLIC_ROUTES.ABOUT} element={<About />} />
            <Route
              path={PUBLIC_ROUTES.WEALTH_VISTA_SOLUTION}
              element={<WealthVistaSolution />}
            />

            <Route
              path={PROTECTED_ROUTES.CONTACT}
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route path={PUBLIC_ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
            <Route path={PUBLIC_ROUTES.PRIVACY_POLICY} element={<Privacy />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    isSignedIn
                      ? PUBLIC_ROUTES.WEALTH_VISTA_SOLUTION
                      : PUBLIC_ROUTES.HOME
                  }
                />
              }
            />
          </Routes>
        </div>
        {!isSignedIn ? <Footer /> : null}
      </div>
    </Router>
  );
}

export default App;
