import React from "react";
import Button from "../../Button";
import contentJSON from "../../../constants/content.json";
import ArrowIcon from "../../ArrowIcon";
import GradientText from "../../GradientText";
import Typography from "../../Typography";

const HeroSection = () => {
  const content = contentJSON.home.heroSection;

  const navigateToInsights = () => {
    const insightsElement = document.getElementById("insights");
    if (insightsElement) {
      insightsElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      className="relative flex flex-col items-center justify-center overflow-hidden bg-black h-[calc(100vh-6rem)] "
      id="top"
    >
      <div>
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 opacity-30 object-cover w-full h-full"
        >
          <source
            src="https://d358nbhtqb8br8.cloudfront.net/bg-2.mp4"
            type="video/mp4"
          />
        </video>
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent" />
      </div>

      <div className="relative z-10 mb-8 w-10/12 2xl:w-[1700px] text-center md:w-[62%]">
        <Typography
          variant="title"
          className="inline-block !font-medium text-white md:text-6xl 2xl:text-8xl"
        >
          {content.desc1}
          <GradientText
            text={content.desc2}
            className="py-1 2xl:py-4 2xl:text-8xl"
          />
          <GradientText
            text={content.desc3}
            className="py-1 2xl:py-4 2xl:text-8xl"
          />
          {content.desc4}
        </Typography>
      </div>
      <div className="relative z-10">
        <ArrowIcon
          width="100px"
          height="75px"
          className="absolute left-[-70px] md:left-[-130px]"
        />
        <Button
          variant="white"
          className="!rounded-[35px] !bg-blue text-xs font-medium text-white md:text-base 2xl:text-2xl 2xl:h-14"
          onClick={navigateToInsights}
        >
          {content.btnText}
        </Button>
      </div>
    </div>
  );
};

export default HeroSection;
