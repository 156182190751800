import React, { FC } from "react";
import classNames from "classnames";
import Loader from "../assets/svgs/loader";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: "white" | "gradient" | "outline";
  disabled?: boolean;
  loading?: boolean;
}

const Button: FC<IButton> = ({
  className,
  variant = "white",
  disabled = false,
  loading = false,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        "h-8 w-fit cursor-pointer rounded-[10px] px-6 text-13 md:h-12",
        {
          "bg-gradient-to-r from-primary-blue to-secondary-blue text-white":
            variant === "gradient",
          "bg-white text-primary-blue": variant === "white",
          "border border-white bg-[#0265A7] text-white": variant === "outline",
          "opacity-60": disabled,
          "w-[100px]": loading,
        },
        className
      )}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
