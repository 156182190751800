import React, { useState } from "react";
import ReactPlayer from "react-player";
import PlaceholderImage from "../assets/images/VideoPlaceholder.png";
import PlayButton from "../assets/svgs/PlayButton.svg";

interface Props {
  url: string;
  placeholder?: string;
}

const Video: React.FC<Props> = ({ url, placeholder }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="relative overflow-hidden w-full flex justify-center my-12">
      <div
        className="inline-block rounded-[25px] p-[2px] w-[95%] sm:w-[80%] md:w-[70%] lg:w-[50%]"
        style={{
          background:
            "linear-gradient(199.76deg, #03C9ED 8.44%, #0265A7 90.35%)",
        }}
      >
        <div className="rounded-[25px] overflow-hidden w-full">
          <div className="inset-0 bg-lines-pattern bg-no-repeat absolute -left-32 top-2 -z-10 hidden md:block" />
          <div className="section-gradient section-gradient-right top-10 -z-10 hidden md:block" />

          <ReactPlayer
            url={url}
            controls={true}
            playing={isPlaying}
            volume={0.8}
            width="100%"
            height="auto"
            light={
              <img
                src={placeholder ? placeholder : PlaceholderImage}
                alt="Thumbnail"
                className="w-full h-auto"
              />
            }
            playIcon={
              <div
                onClick={handlePlayClick}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer z-10"
              >
                <img src={PlayButton} alt="PlayButton" />
              </div>
            }
            style={{
              borderRadius: "25px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
