import classNames from "classnames";
import React, { TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textAreaClassName?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  textAreaClassName,
  label,
  ...rest
}) => {
  return (
    <div className="mb-4">
      {label ? (
        <label
          htmlFor="message"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      ) : null}
      <textarea
        id="message"
        {...rest}
        className={classNames(
          "block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-white focus:outline-none placeholder:!text-[#B7B7B7]",
          textAreaClassName
        )}
      />
    </div>
  );
};

export default TextArea;
