import React, { InputHTMLAttributes } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  checkboxClassName?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxClassName,
  label,
  ...rest
}) => {
  return (
    <div className="mb-4">
      <label className="flex items-center">
        <input
          type="checkbox"
          {...rest}
          className={`rounded border-gray-300 focus:ring-primary-blue ${checkboxClassName}`}
        />
        <span className="ml-2 text-[13px] text-white">{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
