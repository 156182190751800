import React, { useState } from "react";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import Typography from "../../components/Typography";
import { EMAIL, PHONE_NUMBER } from "../../constants/common";
import contentJSON from "../../constants/content.json";
import toast from "react-hot-toast";
import { useAuth } from "../../provider/AuthProvider";
import ReverseLines from "../../assets/svgs/ReverseLines.svg";

const Contact: React.FC = () => {
  const content = contentJSON.contact;
  const { user } = useAuth();

  const [inquiry, setInquiry] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!inquiry) {
      return;
    }
    try {
      setLoading(true);
      const data = {
        date: new Date().toLocaleDateString("en-US"),
        id: user.email,
        inquiry,
      };
      const appScriptWeb =
        process.env.REACT_APP_GOOGLE_APP_SCRIPT_WEB_CONTACT || "";
      await fetch(appScriptWeb, {
        redirect: "follow",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      });
      toast.success("Request submitted successfully");
    } catch (error) {
      toast.error((error as Error).message);
      console.error("Error:", error);
    }
    setInquiry("");
    setLoading(false);
  };

  return (
    <div className="2xl:w-[1500px] mx-auto h-screen">
      <div className="absolute inset-0 z-10 bg-about-lines-pattern bg-no-repeat -mt-24" />
      <img
        src={ReverseLines}
        className="absolute -bottom-32 right-0 hidden lg:block "
      />
      <div className="bg-black py-10 md:pt-20">
        <div className="relative z-20 mx-auto w-11/12 lg:w-1/2 2xl:max-w-[1700px]">
          <Typography
            variant="heading"
            block
            className="mb-6 text-center text-white md:mb-10 !font-medium"
          >
            {content.title}
          </Typography>
          <div className="space-y-7">
            {content.description.map((item, index) => (
              <Typography
                key={index}
                block
                className="text-center text-white !font-normal"
              >
                {item}
              </Typography>
            ))}
            <Typography block className="text-center text-white !font-normal">
              {EMAIL}
              <span className="block">{PHONE_NUMBER}</span>
            </Typography>
            <Typography block className="text-center text-white !font-normal">
              {content.lookForward}
            </Typography>
            <div className="mx-auto">
              <TextArea
                placeholder={content.placeholder}
                rows={4}
                textAreaClassName="h-56 !bg-black border border-white"
                value={inquiry}
                onChange={(event) => setInquiry(event.target.value)}
              />
            </div>
            <div className="flex justify-center" onClick={handleSubmit}>
              <Button
                variant="outline"
                loading={loading}
                className="border-none w-[150px]"
              >
                {content.btnText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
