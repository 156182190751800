import React, { useEffect, useState, useRef } from "react";
import Typography from "./Typography";
import { formatNumber } from "../utils/common";

interface Props {
  data: {
    title: string;
    count: string;
    symbol?: string;
  };
}

const Stats: React.FC<Props> = ({ data }) => {
  const { title, count, symbol } = data;

  const statsRef = useRef<HTMLDivElement | null>(null);

  const [currentCount, setCurrentCount] = useState(0);

  const [isInView, setIsInView] = useState(false);

  const animateCount = (target: number) => {
    const increment = Math.ceil(target / 100);
    const countInterval = setInterval(() => {
      setCurrentCount((prev) => {
        if (prev < target) {
          return prev + increment;
        } else {
          clearInterval(countInterval);
          return target;
        }
      });
    }, 30);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isInView) {
          setIsInView(true);
        }
      },
      { threshold: 0.5 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, [isInView]);

  useEffect(() => {
    const targetCount = isNaN(Number(count)) ? 0 : Number(count);
    if (isInView && targetCount > 0) {
      animateCount(targetCount);
    }
  }, [isInView, count]);

  return (
    <div ref={statsRef} className="flex flex-col items-center gap-1 w-64">
      <Typography
        className="text-white !font-semibold text-4xl !font-poppins 2xl:text-6xl"
        variant="heading"
      >
        {`${!symbol ? formatNumber(currentCount) : currentCount}${symbol ? symbol : ""}`}
      </Typography>
      <Typography className="text-white text-[15px] md:text-[15px] !font-medium text-center 2xl:text-xl">
        {title}
      </Typography>
    </div>
  );
};

export default Stats;
