import React, { useState, useRef } from "react";
import Button from "./Button";
import Input from "./Input";
import Modal from "./Modal";
import Typography from "./Typography";
import NamedLogo from "../assets/svgs/namedLogo.svg";

import contentJSON from "../constants/content.json";
import { isValidEmail, isValidPhoneNumber } from "../utils/common";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import toast from "react-hot-toast";
import Loader from "../assets/svgs/loader";

interface IRequestTrialModal {
  modalOpen: boolean;
  closeModal: () => void;
}

const initialFormData = {
  firstName: "",
  lastName: "",
  workEmail: "",
  phone: "",
  company: "",
};

const RequestTrialModal: React.FC<IRequestTrialModal> = ({
  modalOpen,
  closeModal,
}) => {
  const content = contentJSON.requestTrialModal;
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [errors, setErrors] = useState(initialFormData);
  const [formData, setFormData] = useState(initialFormData);
  const [recaptchaLoading, setRecaptchaLoading] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "phone" && value !== "" && !isValidPhoneNumber(value)) {
      setErrors({
        ...errors,
        phone: content.phoneNumberFormatError,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let isValid = true;
    const newErrors = { ...initialFormData };

    if (!formData.firstName) {
      newErrors.firstName = content.firstName;
      isValid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = content.lastName;
      isValid = false;
    }
    if (!formData.workEmail) {
      newErrors.workEmail = content.emailError;
      isValid = false;
    } else if (!isValidEmail(formData.workEmail)) {
      newErrors.workEmail = content.emailInvalidError;
      isValid = false;
    }
    if (!formData.phone) {
      newErrors.phone = content.phoneError;
      isValid = false;
    } else if (!isValidPhoneNumber(formData.phone)) {
      newErrors.phone = content.invalidNumber;
      isValid = false;
    }
    if (!formData.company) {
      newErrors.company = content.companyError;
      isValid = false;
    }
    setErrors(newErrors);
    if (isValid) {
      try {
        setLoading(true);
        const data = {
          date: new Date().toLocaleDateString("en-US"),
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.workEmail,
          phone: formData.phone,
          company: formData.company,
        };
        const appScriptWeb =
          process.env.REACT_APP_GOOGLE_APP_SCRIPT_WEB_DEMO || "";
        await fetch(appScriptWeb, {
          redirect: "follow",
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        });
        closeModal();
        toast.success("Request submitted successfully");
      } catch (error) {
        toast.error((error as Error).message);
        console.error("Error:", error);
      }
      setLoading(false);
    }
  };

  const onReCAPTCHAChange = (captchaCode: string | null) => {
    if (!captchaCode) {
      setIsVerified(false);
      return;
    }
    setIsVerified(true);
  };

  const onReCAPTCHALoad = () => {
    setTimeout(() => setRecaptchaLoading(false), 100);
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      className="h-fit max-h-[96%] max-w-[600px] mx-auto flex flex-col"
    >
      <div className="mb-6 space-y-2 text-center">
        <img src={NamedLogo} alt="Logo" className="mx-auto block w-40" />
        <Typography
          variant="heading"
          block
          noBold
          className="text-center text-white md:text-[26px] text-xl"
        >
          {content.title}
        </Typography>
      </div>
      <form className="py-2 " onSubmit={handleSubmit}>
        <Input
          placeholder={content.firstName}
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          error={errors.firstName}
        />
        <Input
          placeholder={content.lastName}
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          error={errors.lastName}
        />
        <Input
          placeholder={content.workEmail}
          name="workEmail"
          value={formData.workEmail}
          onChange={handleChange}
          error={errors.workEmail}
        />
        <Input
          placeholder={content.phone}
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={errors.phone}
        />
        <Input
          placeholder={content.company}
          name="company"
          value={formData.company}
          onChange={handleChange}
          error={errors.company}
        />
        <div className="my-recaptcha-container h-14">
          {recaptchaLoading && (
            <div className="flex justify-center items-center h-14 mb-6">
              <Loader />
            </div>
          )}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="normal"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            onChange={onReCAPTCHAChange}
            asyncScriptOnLoad={onReCAPTCHALoad}
          />
        </div>
        <div className="flex w-full justify-center mt-4">
          <Button
            variant="outline"
            type="submit"
            disabled={!isVerified}
            loading={loading}
          >
            {content.btnText}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RequestTrialModal;
