export const PUBLIC_ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  WEALTH_VISTA_SOLUTION: "/wealth-vista-solution",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
};

export const PROTECTED_ROUTES = {
  CONTACT: "/contact",
};
