import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { PUBLIC_ROUTES } from ".";

interface Props {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const { isSignedIn } = useAuth();

  if (!isSignedIn) {
    return <Navigate to={PUBLIC_ROUTES.HOME} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
