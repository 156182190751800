import Bars from "../assets/svgs/bars.svg";
import contentJSON from "./content.json";
import wvImageOne from "../assets/images/v1.png";
import wvImageTwo from "../assets/images/v2.jpg";
import wvImageThree from "../assets/images/v3.jpg";
import wvImageFour from "../assets/images/v4.jpg";
import wvImageFive from "../assets/images/v5.png";
import wvImageSix from "../assets/images/v6.png";
import MergeIcon from "../assets/svgs/mergeIcon.svg";
import FundInfoIcon from "../assets/svgs/fundInfo.svg";
import FundIdenIcon from "../assets/svgs/funIdentifiers.svg";
import Benefits1 from "../assets/svgs/Benefits1.svg";
import Benefits2 from "../assets/svgs/Benefits2.svg";
import Benefits3 from "../assets/svgs/Benefits3.svg";
import Benefits4 from "../assets/svgs/Benefits4.svg";
import Benefits5 from "../assets/svgs/Benefits5.svg";
import Benefits6 from "../assets/svgs/Benefits6.svg";
import BenchMarkIcon from "../assets/svgs/benchmark.svg";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "../routes";

export enum NAV_LINKS_NAME {
  HOME = "Home",
  SOLUTION = "Solutions",
  ABOUT = "About Us",
  CONTACT = "Contact Us",
  LOGIN = "Login",
  REQUEST_A_TRIAL = "Request a Trial",
  PRIVACY_POLICY = "Privacy Policy",
  TERMS_OF_USE = "Terms Of Use",
}

export const NAV_LINK = [
  {
    title: NAV_LINKS_NAME.SOLUTION,
    link: PUBLIC_ROUTES.WEALTH_VISTA_SOLUTION,
  },
  {
    title: NAV_LINKS_NAME.ABOUT,
    link: PUBLIC_ROUTES.ABOUT,
  },
  {
    title: NAV_LINKS_NAME.CONTACT,
    link: PROTECTED_ROUTES.CONTACT,
  },
];

export const FOOTER_LINK = [
  {
    title: NAV_LINKS_NAME.PRIVACY_POLICY,
    link: PUBLIC_ROUTES.PRIVACY_POLICY,
  },
  {
    title: NAV_LINKS_NAME.TERMS_OF_USE,
    link: PUBLIC_ROUTES.TERMS_OF_USE,
  },
];

export const PHONE_NUMBER = "(732) 290-5662";
export const EMAIL = "Max.Sparshatt@WealthVista.com";
export const CONTACT_EMAIL = "info@wealthvista.com";
export const LINKED_IN_URL = "https://www.linkedin.com/company/wealthvistaus";
export const WEALTH_VISTA_CARDS = [
  {
    title: contentJSON.wealthVistaSolution.cardText.firstCard,
    image: wvImageOne,
    url: "https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaMutualFundDashboard/WealthVistaMutualFundDashboardDark?:embed=yes&:toolbar=no",
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.secondCard,
    image: wvImageTwo,

    url: "https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaBrokerDealerDashboard/WealthVistaBrokerDealerDashboardDark?:embed=yes&:toolbar=no",
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.thirdCard,
    image: wvImageThree,
    url: "https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaMutualFundDatabase/MutualFundDatabase?:embed=yes&:toolbar=no",
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.fourthCard,
    image: wvImageFour,
    url: "https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaBrokerDealerDatabase/BrokerDealerDatabase?:embed=yes&:toolbar=no",
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.fifthCard,
    image: wvImageFive,
    url: "https://prod-useast-b.online.tableau.com/t/wealthvista/views/WealthVistaMutualFundComparisonTool/Single-FundDark?:embed=yes&:toolbar=no",
  },
  {
    title: contentJSON.wealthVistaSolution.cardText.sixthCard,
    image: wvImageSix,
  },
];

export const COMPREHENSIVE_SECTION_IMAGES = [
  MergeIcon,
  Bars,
  BenchMarkIcon,
  FundInfoIcon,
  FundIdenIcon,
];

export const FUNDS_LISTING = [
  {
    title: "Fund Positioning",
    wealthVistaMutualFund: true,
    wealthVistaBrokerDealer: true,
  },
  {
    title: "Firm Positioning",
    wealthVistaMutualFund: true,
    wealthVistaBrokerDealer: true,
  },
  {
    title: "Industry Agnostic",
    wealthVistaMutualFund: true,
    wealthVistaBrokerDealer: false,
  },
  {
    title: "Investment Vehicle  Agnostic",
    wealthVistaMutualFund: false,
    wealthVistaBrokerDealer: true,
  },
];

const BENEFITS_ICONS = [
  Benefits1,
  Benefits2,
  Benefits3,
  Benefits4,
  Benefits5,
  Benefits6,
];

export const BENEFITS_CARDS = () => {
  return contentJSON.home.benefits.cards.map((card, index) => ({
    ...card,
    icon: BENEFITS_ICONS[index],
  }));
};