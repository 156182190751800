import React, { useEffect, useState } from "react";
import Typography from "../../Typography";
import contentJSON from "../../../constants/content.json";
import GradientText from "../../GradientText";
import BorderedCross from "../../../assets/svgs/BorderedCross.svg";
import BorderedTick from "../../../assets/svgs/BorderedTick.svg";
import { FUNDS_LISTING } from "../../../constants/common";

const FundInsights = () => {
  const content = contentJSON.home.fundInsights;

  const [currentDescription, setCurrentDescription] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [animationState, setAnimationState] = useState("fadeIn");
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    if (!isAnimating) return;

    let timeout: NodeJS.Timeout;
    const descriptionLength = content.whyDescription[currentDescription].length;

    if (animationState === "fadeIn") {
      // Fade in letters one by one
      timeout = setTimeout(() => {
        setLetterIndex((prev) => prev + 1);
      }, 10); // Adjust this speed to control how fast the letters fade in

      // Once all letters are revealed, transition to fadeOut
      if (letterIndex === descriptionLength) {
        setTimeout(() => {
          setAnimationState("fadeOut");
          setLetterIndex(content.whyDescription[currentDescription].length);
        }, 1000); // Wait for 1 second after fadeIn before starting fadeOut
      }
    } else if (animationState === "fadeOut") {
      // Fade out letters from the end of the sentence
      timeout = setTimeout(() => {
        setLetterIndex((prev) => prev - 1);
      }, 50); // Adjust this speed to control how fast the letters fade out

      // Once all letters are faded out, move to the next description
      if (letterIndex === 0) {
        setTimeout(() => {
          setCurrentDescription(
            (prev) => (prev + 1) % content.whyDescription.length
          );
          setLetterIndex(0); // Reset letterIndex for the new sentence
          setAnimationState("fadeIn"); // Start fadeIn for the next sentence
          setIsAnimating(true); // Trigger the next animation cycle
        }, 500); // Optional delay after fadeOut before switching to the next description
      }
    }

    return () => clearTimeout(timeout);
  }, [letterIndex, animationState, currentDescription, isAnimating]);

  const descriptionText = content.whyDescription[currentDescription];

  return (
    <div className="relative pt-12 py-24 bg-black/60 2xl:w-[1700px] mx-auto">
      <div>
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 h-[100%] w-full object-cover opacity-25 -mt-24"
        >
          <source
            src="https://d358nbhtqb8br8.cloudfront.net/bg-1.mp4"
            type="video/mp4"
          />
        </video>
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
      </div>

      <div className="flex flex-col lg:flex-row w-11/12 mx-auto relative">
        <div className="lg:w-[60%] space-y-6 flex flex-col">
          <div>
            <Typography
              variant="title"
              className="inline-block !font-medium text-white md:text-5xl"
            >
              {content.h1.part1}
              <GradientText
                text={content.h1.part2}
                className="md:text-5xl pb-1"
              />
            </Typography>
            <Typography
              variant="title"
              className="inline-block !font-medium text-white md:text-5xl"
            >
              {content.h2.part1}
              <GradientText
                text={content.h2.part2}
                className="md:text-5xl pb-1"
              />
            </Typography>
          </div>
          <Typography className="!font-light text-gray-300 md:!text-lg mt-2">
            {content.description}
          </Typography>
          <Typography
            variant="heading"
            className="text-white md:text-3xl !font-medium !mt-14 !font-inter"
          >
            {content.why}
          </Typography>

          <div className="relative h-20">
            <Typography className="!font-light text-white !text-lg md:!text-lg mt-2 absolute">
              {descriptionText.split("").map((letter, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      opacity:
                        animationState === "fadeIn" && index < letterIndex
                          ? 1 // Letters fade in
                          : animationState === "fadeOut" && index < letterIndex
                            ? 1 // Letters fade out from the end of the sentence
                            : 0, // Letters stay hidden until the animation starts
                      transition: "opacity 0.1s ease-out", // Smooth transition for fading
                    }}
                  >
                    {letter}
                  </span>
                );
              })}
            </Typography>
            <Typography className="!font-light !text-lg md:!text-lg mt-2 text-white/40 absolute">
              <span
                style={{
                  transition: `opacity ${animationState === "fadeIn" ? 1 : 5}s ease-out`, // Transition duration changes based on fadeIn or fadeOut
                  opacity: animationState === "fadeIn" ? 1 : 0, // Opacity changes based on fadeIn or fadeOut
                }}
              >
                {descriptionText}
              </span>
            </Typography>
          </div>
        </div>

        <div className="lg:w-[40%] flex flex-col justify-between items-end mt-10 lg:mt-0">
          <div className="grid grid-cols-3 gap-4 lg:w-[85%] w-full">
            {content.fundsColumns.map((heading, index) => (
              <div key={`${heading}-${index}`}>
                <Typography className="text-white text-sm md:text-base !font-thin line-clamp-2 text-center md:w-80% w-full">
                  {heading}
                </Typography>
              </div>
            ))}
          </div>

          {FUNDS_LISTING.map((info, index) => (
            <div
              key={index}
              className="grid grid-cols-3 lg:grid-cols-4 gap-4 lg:w-[85%] mt-10 lg:mt-0 w-full"
            >
              <Typography className="text-white text-sm md:text-base !font-thin line-clamp-2 lg:col-span-2 lg:-ml-8">
                {info.title}
              </Typography>
              <div className="w-full flex justify-center lg:-ml-8">
                <img
                  src={
                    info.wealthVistaMutualFund ? BorderedTick : BorderedCross
                  }
                  alt={`wealthVistaMutualFund-${index}`}
                  className="h-9 w-9"
                />
              </div>
              <div className="w-full flex justify-center">
                <img
                  src={
                    info.wealthVistaBrokerDealer ? BorderedTick : BorderedCross
                  }
                  alt={`wealthVistaBrokerDealer-${index}`}
                  className="h-9 w-9"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FundInsights;
