import React from "react";
import contentJSON from "../../constants/content.json";
import AboutImageOne from "../../assets/svgs/aboutOne.svg";
import AboutImageTwo from "../../assets/svgs/aboutTwo.svg";
import ReverseLines from "../../assets/svgs/ReverseLines.svg";
import AboutSectionCard from "./AboutSectionCard";

const About = () => {
  const content = contentJSON.home.about;
  return (
    <div className="relative bg-black pt-[103px] pb-24" id="about">
      <div className="absolute inset-0 z-10 bg-about-lines-pattern bg-no-repeat -mt-24"></div>
      <img src={ReverseLines} className="absolute bottom-0 right-0 -mb-24" />
      <div className="relative mx-auto 2xl:w-[1700px]">
        <AboutSectionCard
          title={content.titleOne}
          desc={content.textOne}
          image={AboutImageOne}
        />
        <AboutSectionCard
          title={content.titleTwo}
          desc={content.textTwo}
          image={AboutImageTwo}
          className="mt-24"
          imageOnLeft
        />
      </div>
    </div>
  );
};

export default About;
