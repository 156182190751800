export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  return /^[\d+]+$/.test(phoneNumber);
};

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatNumber = (number: number | null | undefined) => {
  if (number === null || number === undefined || isNaN(number)) return "0.00";
  if (number < 100_000) {
    return new Intl.NumberFormat("en-PH", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(number);
  } else if (number < 10_000_000) {
    return `${(number / 1_000_000).toFixed(1)}M`; // For 100K to less than 10M
  } else if (number < 1_000_000_000) {
    return `${Math.round(number / 1_000_000)}M`; // For 10M to less than 1B
  }
  return `${(number / 1_000_000_000).toFixed(1)}B`; // For 1B and above
};