import React, { useEffect } from "react";
import Cross from "../assets/svgs/shield-cross.svg";
import classNames from "classnames";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  className,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  // Prevent scrolling on the body when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-black/90 scrollbarHidden"
          onClick={() => onClose()}
        >
          <div
            className={classNames(
              "relative w-11/12 overflow-y-scroll rounded-[30px] p-[1px] sm:w-10/12 md:w-[492px] scrollbarHidden",
              className
            )}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            onKeyDown={handleKeyDown}
            onClick={handleModalContentClick}
          >
            <div className="absolute inset-0 z-[-1] rounded-[30px] bg-gradient-to-r from-primary-blue to-secondary-blue" />
            <div className="relative h-full overflow-y-auto rounded-[30px] bg-black p-8 scrollbarHidden">
              <div className="flex justify-end">
                <button
                  className="text-secondary-blue hover:text-primary-blue focus:outline-none"
                  onClick={onClose}
                  aria-label="Close modal"
                >
                  <img src={Cross} alt="Cross" />
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
