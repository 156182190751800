import React from "react";

interface SVGIconProps {
  width?: string | number;
  height?: string | number;
  className?: string;
}

const ArrowIcon: React.FC<SVGIconProps> = ({
  width = "100%",
  height = "auto",
  className,
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 101 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39345 6.42794C2.24334 12.3398 5.63572 18.2037 10.834 21.0291C14.0291 22.7684 17.8895 23.4876 20.4203 26.1052C24.5653 30.3958 23.235 37.6244 26.1642 42.8232C28.8562 47.6017 35.107 49.8031 40.4153 48.4062C45.7236 47.0093 49.9027 42.4711 51.5654 37.2459C52.1988 35.2596 52.4894 33.0406 51.6727 31.1216C50.8559 29.2025 48.6356 27.7781 46.6486 28.4172C45.7129 28.7209 44.9539 29.4193 44.2947 30.1456C37.82 37.2594 37.8495 48.3337 41.0525 57.398C42.8835 62.5782 45.7733 67.6181 50.3445 70.6739C54.2665 73.2964 59.1102 74.2321 63.8334 74.2708C78.0669 74.3878 92.0716 66.2402 98.9891 53.817M98.9891 53.817L99.3068 58.8396M98.9891 53.817L94.5419 55.2054"
      stroke="white"
      strokeOpacity="0.8"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
