import React, { useState } from "react";
import { BENEFITS_CARDS } from "../../../constants/common";
import BenefitsBackground from "../../../assets/svgs/BenefitsBackground.svg";
import Video from "../../video";

const Benefit = () => {
  const [activeBenefit, setActiveBenefit] = useState<number | null>(null);

  return (
    <>
      <div className="relative mx-auto 2xl:w-[1700px] px-4 z-20 lg:min-h-80">
        <div className="w-[95%] flex-wrap lg:flex-nowrap flex mx-auto -mt-4 gap-8 md:gap-4">
          {BENEFITS_CARDS().map((benefit, index) => (
            <div
              key={benefit.title}
              className="group relative rounded-[11.5px] bg-gradient-to-b from-primary-blue to-secondary-blue p-[1px] w-80 md:w-96  lg:w-60 hover:lg:w-[400px] transition-all duration-500 ease-in-out mx-auto"
              onMouseEnter={() => {
                setTimeout(() => setActiveBenefit(index), 250);
              }}
              onMouseLeave={() => setActiveBenefit(null)}
            >
              <div className="relative overflow-hidden rounded-[11.5px] bg-black p-4 md:p-2 flex flex-col items-center justify-between gap-2 h-80">
                <img
                  src={BenefitsBackground}
                  className="absolute bottom-0 right-0"
                />
                <div className="w-[75px] h-[75px] rounded-full border-white border flex items-center justify-center md:mt-12 shrink-0 mb-8 group-hover:lg:!mb-2 group-hover:lg:mt-4">
                  <img
                    src={benefit.icon}
                    alt={benefit.title}
                    className="object-cover size-8"
                  />
                </div>
                <div
                  className={`mb-4 flex flex-col justify-end sm:justify-start sm:h-[70%] md:h-fit group-hover:lg:h-[55%] group-hover:xl:h-[45%]`}
                >
                  <span className="!font-light text-center text-[17px] md:text-[17px] block bg-gradient-to-b to-primary-blue from-secondary-blue bg-clip-text text-transparent font-inter whitespace-pre-wrap">
                    {benefit.title}
                  </span>
                  <span
                    className={`!font-light text-center text-[14px] md:text-[16px] text-white lg:hidden group-hover:lg:block w-11/12 font-inter mx-auto transition-opacity duration-500 ease-in-out ${
                      activeBenefit === index || "lg:opacity-0"
                    }`}
                  >
                    {benefit.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Video url="https://d358nbhtqb8br8.cloudfront.net/wv-overview.webm" />
    </>
  );
};

export default Benefit;
