import React from "react";
import { Link } from "react-router-dom";
import contentJSON from "../../constants/content.json";
import { FOOTER_LINK, LINKED_IN_URL } from "../../constants/common";
import LinkedInLogo from "../../assets/svgs/linkedInIcon.svg";

const Footer: React.FC = () => {
  const content = contentJSON.footer;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex flex-col z-50 text-white">
      <div className="h-[1px] rounded bg-white w-11/12 mx-auto" />
      <div className="w-11/12 mx-auto flex flex-col md:flex-row py-4 gap-6 justify-between">
        <div className="flex md:gap-14 gap-6 flex-col md:flex-row">
          <div className="text-center text-xs my-auto">
            © {new Date().getFullYear()} {content.copyright}
          </div>
          <ul className="flex flex-col items-center gap-3 md:flex-row md:gap-8 xl:gap-14 underline text-xs">
            {FOOTER_LINK.map((item) => (
              <Link
                to={item.link}
                key={item.title}
                onClick={() => scrollToTop()}
              >
                {item.title}
              </Link>
            ))}
          </ul>
        </div>
        <div className="flex gap-4 items-center justify-center py-3">
          <div className="text-center text-xs">{content.follow}</div>
          <a href={LINKED_IN_URL} target="_blank" rel="noopener noreferrer">
            <img src={LinkedInLogo} alt="LinkedIn Logo" className="h-6" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
