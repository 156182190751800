import React, { useEffect, useState } from "react";
import Typography from "../../components/Typography";
import FundInsights from "../../components/Home/FundInsights";
import Benefits from "../../components/Home/Benefits";
import { useAuth } from "../../provider/AuthProvider";
import Tableau from "../../components/tableau/Tableau";
import { cn } from "../../utils/common";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useHeader } from "../../provider/HeaderProvider";
import { WEALTH_VISTA_CARDS } from "../../constants/common";
import ReverseLines from "../../assets/svgs/ReverseLines.svg";
import Loader from "../../assets/svgs/loader";

const WealthVistaSolution: React.FC = () => {
  const { isSignedIn } = useAuth();
  const { hideHeader, setHideHeader } = useHeader();
  const [loading, setLoading] = useState(false);
  const [tableauURL, setTableauURL] = useState("");
  const [hideCards, setHideCards] = useState(false);

  const handleSidebarClose = () => {
    setHideHeader(false);
    setHideCards(true);
    setTimeout(() => {
      setTableauURL("");
      setHideCards(false);
    }, 1000);
  };

  useEffect(() => {
    if (isSignedIn) setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, [isSignedIn]);

  useEffect(() => {
    // Push a state when component mounts
    history.pushState(null, "", window.location.href);

    const handlePopState = (event: PopStateEvent) => {
      if (hideHeader && !hideCards) {
        event.preventDefault(); // Prevent default back navigation
        handleSidebarClose(); // Or whatever custom behavior you need
      }
    };

    // Listen to the popstate event (back/forward navigation)
    window.addEventListener("popstate", handlePopState);

    return () => {
      // Cleanup the event listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, [hideHeader, hideCards]);

  if (!isSignedIn)
    return (
      <div>
        <FundInsights />
        <Benefits />
      </div>
    );
  if (loading)
    return (
      <div className="absolute top-[50%] right-[50%]">
        <Loader />
      </div>
    );

  return (
    <>
      {tableauURL ? (
        <>
          <div
            className={cn(
              "p-1 font-bold w-full flex cursor-pointer items-end align-left justify-end pr-4 bg-gray-500/20",
              { hidden: hideCards }
            )}
            onClick={handleSidebarClose}
          >
            <RiArrowGoBackLine size={24} color="white" />
          </div>
          <Tableau url={tableauURL} hideCards={hideCards} />
        </>
      ) : null}
      <div
        className={`absolute inset-0 z-10 bg-about-lines-pattern bg-no-repeat -mt-24 ${hideHeader ? "hidden" : "block"}`}
      />
      <div
        className={cn("relative min-h-screen pt-10 md:py-20 overflow-hidden", {
          hidden: tableauURL,
        })}
      >
        <img src={ReverseLines} className="absolute bottom-0 right-0" />
        <div className="section-gradient section-gradient-right top-[60%]" />
        <div className="section-gradient section-gradient-left top-[75%]" />
        <div className="md:1/2 relative mx-auto w-10/12 sm:w-1/2 lg:w-9/12">
          <div className="2xl:mx-auto 2xl:max-w-[1700px]">
            <div className="mx-auto grid w-full grid-cols-1 gap-x-10 gap-y-14 lg:grid-cols-2 xl:gap-x-24 2xl:w-2/3">
              {WEALTH_VISTA_CARDS.map((item, index) => (
                <div
                  key={index}
                  className={`overflow-hidden rounded-2xl bg-white hover:drop-shadow-2xl transform transition-transform duration-300 hover:scale-105 z-20 border shadow-xl shadow-gray-800/15 ${!item.url ? "cursor-default" : "cursor-pointer"}`}
                  onClick={() => {
                    if (item.url) {
                      setHideHeader(true);
                      setTableauURL(item.url);
                    }
                  }}
                >
                  <div className="relative">
                    {!item.url && (
                      <Typography
                        block
                        className="absolute inset-0 flex items-center justify-center z-10 text-center text-white !font-medium !text-2xl sm:text-base"
                      >
                        Coming Soon
                      </Typography>
                    )}
                    <img
                      src={item.image}
                      alt="Logo"
                      className="w-full rounded-t-2xl"
                    />
                    {!item.url && (
                      <div className="absolute inset-0 bg-black/90 rounded-t-2xl" />
                    )}
                  </div>
                  <Typography
                    block
                    className="my-3 text-center text-dark-gray !font-medium text-sm sm:text-base"
                  >
                    {item.title}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WealthVistaSolution;
