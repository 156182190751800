import classNames from "classnames";
import React, { InputHTMLAttributes, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  label?: string;
  inputClassName?: string;
  error?: string;
}

const Input: React.FC<InputProps> = ({
  type = "text",
  inputClassName,
  error,
  label,
  ...rest
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div className="mb-5">
      {label ? (
        <label
          htmlFor="inputField"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      ) : null}
      <div className="relative">
        <input
          type={type === "password" && passwordVisible ? "text" : type}
          id={rest.placeholder}
          {...rest}
          className={classNames(
            "block w-full rounded-lg border border-gray-800 bg-black px-5 py-4 text-sm text-white placeholder:text-white focus:outline-none",
            inputClassName
          )}
        />
        {type === "password" && (
          <span
            onClick={togglePasswordVisible}
            className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
          >
            {passwordVisible ? (
              <AiOutlineEyeInvisible color="white" size={20} />
            ) : (
              <AiOutlineEye color="white" size={20} />
            )}
          </span>
        )}
      </div>
      {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
    </div>
  );
};

export default Input;
