import React from "react";
import classNames from "classnames";

interface ITypography {
  children: React.ReactNode;
  className?: string;
  variant?: "title" | "heading" | "subheading" | "xl" | "lg" | "p";
  noBold?: boolean;
  block?: boolean;
  textGradient?: boolean;
}

const Typography = ({
  variant = "p",
  className = "",
  noBold = false,
  block = false,
  textGradient = false,
  children,
}: ITypography) => (
  <span
    className={classNames(
      "text-black",
      {
        block,
        "mx-1 inline-block": !block,
        "font-semibold": !noBold,
        "text-4xl md:text-5xl": variant === "title",
        "text-2xl font-medium leading-[40px] md:text-4xl":
          variant === "heading",
        "text-lg lg:text-[26px]": variant === "subheading",
        "text-lg font-normal": variant === "p",
        "animate-gradientShift bg-gradient-to-r from-primary-blue to-secondary-blue bg-[length:200%_100%] bg-clip-text text-transparent":
          textGradient,
      },
      className
    )}
  >
    {children}
  </span>
);

export default Typography;