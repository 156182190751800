import React, { FC } from "react";
import classNames from "classnames";
import Typography from "../../components/Typography";

interface ISectionCard {
  image: string;
  title: string;
  desc: string;
  className?: string;
  sectionClassName?: string;
  imageOnLeft?: boolean;
}

const AboutSectionCard: FC<ISectionCard> = ({
  title,
  desc,
  image,
  className,
  sectionClassName,
  imageOnLeft,
}) => {
  return (
    <div
      className={classNames(
        "mx-auto w-10/12 lg:w-[78%] flex flex-col lg:flex-row gap-12 items-center justify-center",
        {
          "lg:flex-row-reverse": imageOnLeft,
        },
        className
      )}
    >
      <div className={classNames("lg:w-1/2 space-y-6", sectionClassName)}>
        <Typography
          variant="heading"
          className="text-white md:text-[32px] 2xl:text-4xl !font-medium font-inter"
        >
          {title}
        </Typography>
        <Typography className="text-[15.5px] md:text-[15.5px] 2xl:text-xl !text-gray-800 leading-8 !font-normal font-inter 2xl:leading-8">
          {desc}
        </Typography>
      </div>
      <div className={`flex lg:w-1/2 z-40 ${imageOnLeft ? "justify-end" : ""}`}>
        <img alt={title} src={image} className="rounded-xl" />
      </div>
    </div>
  );
};

export default AboutSectionCard;
