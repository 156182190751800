import React from "react";
import Typography from "./Typography";
import classNames from "classnames";

interface ISideSection {
  icon: string;
  data?: {
    title: string;
    desc?: string;
  };
  className?: string;
}

const ImageWithTextSection: React.FC<ISideSection> = ({
  data,
  icon,
  className,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <div
          className={classNames(
            "flex min-w-12 2xl:min-w-20 items-center justify-center",
            className
          )}
        >
          <img src={icon} alt={data?.title || ""} width={32} height={32} />
        </div>
        <Typography className="text-white md:!text-[22px] 2xl:!text-3xl text-nowrap !font-poppins">
          {data?.title}
        </Typography>
      </div>
      <div>
        <Typography
          block
          className="!text-[15px] md:!text-[15px] !font-normal leading-5 text-white !font-inter 2xl:!text-xl"
        >
          {data?.desc}
        </Typography>
      </div>
    </div>
  );
};
export default ImageWithTextSection;
