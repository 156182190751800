import React from "react";
import Typography from "../../components/Typography";
import contentJSON from "../../constants/content.json";
import Markdown from "react-markdown";
import ReverseLines from "../../assets/svgs/ReverseLines.svg";

const TermsOfUse: React.FC = () => {
  const content = contentJSON.termsOfUse;

  return (
    <>
      <div className="absolute inset-0 z-10 bg-about-lines-pattern bg-no-repeat -mt-24 2xl:w-[1700px] overflow-hidden" />
      <img src={ReverseLines} className="absolute bottom-0 right-0 top-[80%]" />
      <img
        src={ReverseLines}
        className="absolute left-0 top-[200%] md:hidden block w-full opacity-50"
      />
      <div className="py-20 px-4">
        <div className="mx-auto w-full 2xl:max-w-[1700px] pb-24">
          <Typography
            variant="heading"
            block
            className="text-center text-white !font-medium text-[32px] font-inter"
          >
            {content.title}
          </Typography>
          <div className="container mx-auto">
            <Typography
              block
              className="mx-auto -mt-1 text-center !text-[#F5F5F5] !font-normal text-lg font-inter"
            >
              {content.lastUpdated}
            </Typography>
            <Typography
              block
              className="mx-auto mt-8 !font-normal !text-lg !text-[#F5F5F5] font-inter whitespace-pre-line"
            >
              {content.description}
            </Typography>
            <ol className="mt-4 space-y-4">
              {content.terms.map((term, index) => (
                <li key={index} className="!font-semibold ml-4 space-y-1">
                  <Typography className="!font-medium !text-2xl !text-[#F5F5F5] font-inter">
                    {term.title}
                  </Typography>
                  <Markdown
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          {...props}
                          className="text-primary-blue underline hover:text-secondary-blue cursor-pointer"
                        />
                      ),
                    }}
                    className="text-[#F5F5F5] !text-lg !font-normal font-inter mt-2 whitespace-pre-line"
                  >
                    {term.content}
                  </Markdown>
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div className="border w-3/5 mx-auto" />
        <div
          className="mx-auto w-full 2xl:max-w-[1700px] pt-24"
          id="social-media-disclaimer"
        >
          <Typography
            variant="heading"
            block
            className="text-center !text-white !font-medium text-[32px] font-inter"
          >
            {content.socialMediaDisclaimer.title}
          </Typography>
          <div className="container mx-auto">
            <Typography
              block
              className="mx-auto -mt-1 text-center !text-[#F5F5F5] !font-normal !text-lg font-inter"
            >
              {content.socialMediaDisclaimer.lastUpdated}
            </Typography>
            <Typography
              block
              className="mx-auto mt-4 !text-[#F5F5F5] !font-normal text-lg font-inter whitespace-pre-line"
            >
              {content.socialMediaDisclaimer.description}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
