import React from "react";
import Modal from "./Modal";
import Typography from "./Typography";
import contentJSON from "../constants/content.json";
import { CONTACT_EMAIL } from "../constants/common";

interface IContactModal {
  modalOpen: boolean;
  closeModal: () => void;
}

const ContactModal: React.FC<IContactModal> = ({ modalOpen, closeModal }) => {
  const content = contentJSON.contactModal;

  return (
    <Modal isOpen={modalOpen} onClose={closeModal}>
      <div className="pb-8">
        <Typography
          variant="heading"
          block
          className="mb-3 text-center !font-normal text-white md:text-[32px]"
        >
          {content.title}
        </Typography>
        <Typography
          block
          className="mb-4 px-3 text-center text-[20px] !font-light text-white"
        >
          {content.description}
        </Typography>
        <div className="mx-auto w-fit">
          <a href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noreferrer">
            <Typography
              textGradient
              className="text-center !font-normal tracking-wide text-secondary-blue cursor-pointer underline"
            >
              {CONTACT_EMAIL}
            </Typography>
          </a>
          <div className="h-[1px] bg-gradient-to-r from-primary-blue to-secondary-blue" />
        </div>
      </div>
    </Modal>
  );
};

export default ContactModal;
