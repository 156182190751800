import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NamedLogo from "../../assets/svgs/namedLogo.svg";
import { FaUserCircle } from "react-icons/fa";
import { signOut } from "aws-amplify/auth";
import toast from "react-hot-toast";
import Cross from "../../assets/svgs/shield-cross.svg";

import contentJSON from "../../constants/content.json";

import { CgMenu } from "react-icons/cg";
import { useEffect, useState } from "react";
import NavItem from "./Navitem";
import { NAV_LINK, NAV_LINKS_NAME } from "../../constants/common";
import LoginModal from "../LoginModal";
import ContactModal from "../ContactModal";
import Button from "../Button";
import Typography from "../Typography";
import { useAuth } from "../../provider/AuthProvider";
import RequestTrialModal from "../RequestATrialModal";
import { PUBLIC_ROUTES } from "../../routes";

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isSignedIn, setIsSignedIn, user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [navLink, setNavLink] = useState(
    NAV_LINK.filter(
      (item) =>
        item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
        item.title !== NAV_LINKS_NAME.PRIVACY_POLICY
    )
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    console.log("HERE", isDropdownOpen);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (title: string) => {
    setModalTitle(title);
    setModalOpen(true);
    setIsOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsOpen(false);
  };

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await signOut();
      localStorage.clear();
      navigate(PUBLIC_ROUTES.HOME);
      setIsSignedIn(false);
    } catch (error) {
      toast.error((error as Error).message);
    }
    setLoading(false);
    toggleDropdown();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (isSignedIn) {
      const filteredLinks = NAV_LINK.filter(
        (item) =>
          item.title !== NAV_LINKS_NAME.HOME &&
          item.title !== NAV_LINKS_NAME.ABOUT &&
          item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
          item.title !== NAV_LINKS_NAME.PRIVACY_POLICY
      );
      setNavLink(filteredLinks);
    } else {
      const filteredLinks = NAV_LINK.filter(
        (item) =>
          item.title !== NAV_LINKS_NAME.TERMS_OF_USE &&
          item.title !== NAV_LINKS_NAME.PRIVACY_POLICY
      );
      setNavLink(filteredLinks);
    }
  }, [isSignedIn]);

  const content = contentJSON.header;
  return (
    <div className="bg-transparent z-50" id="header">
      {modalOpen && (
        <>
          {modalTitle === NAV_LINKS_NAME.LOGIN ||
          modalTitle === NAV_LINKS_NAME.SOLUTION ? (
            <LoginModal modalOpen={modalOpen} closeModal={closeModal} />
          ) : null}
          {modalTitle === NAV_LINKS_NAME.CONTACT && (
            <ContactModal modalOpen={modalOpen} closeModal={closeModal} />
          )}
          {modalTitle === NAV_LINKS_NAME.REQUEST_A_TRIAL && (
            <RequestTrialModal modalOpen={modalOpen} closeModal={closeModal} />
          )}
        </>
      )}
      <nav className="mx-auto flex h-24 items-center justify-between gap-5 px-5 pt-5 text-white md:px-8 xl:px-14 2xl:max-w-[1700px]">
        <div
          className={`1/3 lg:w-1/4 ${isSignedIn ? "cursor-default" : "cursor-pointer"}`}
          onClick={() => (isSignedIn ? null : navigate(PUBLIC_ROUTES.HOME))}
        >
          <img src={NamedLogo} alt="Logo" className="w-36 md:w-44 2xl:w-72" />
        </div>
        <ul className="hidden w-2/4 items-center justify-center gap-5 lg:flex xl:gap-10">
          {navLink.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              pathname={pathname}
              openModal={openModal}
              setIsOpen={setIsOpen}
            />
          ))}
        </ul>
        <div className="w-2/3 flex items-center justify-end gap-3 lg:w-1/4">
          {!isSignedIn ? (
            <>
              <Button
                onClick={() => openModal(NAV_LINKS_NAME.LOGIN)}
                className="sm:!w-[135px] sm:h-12 !rounded-[35px] border border-purple !bg-transparent text-xs text-white md:text-base"
              >
                {content.login}
              </Button>
              <Button
                onClick={() => openModal(NAV_LINKS_NAME.REQUEST_A_TRIAL)}
                className="hidden sm:h-12 !rounded-[35px] bg-[radial-gradient(88.51%_144.79%_at_52.45%_-44.79%,_#45E7FC_0%,_#6665FC_73.56%)] px-2 text-xs font-medium text-white sm:block md:h-12 md:px-3 md:text-base xl:px-5 text-nowrap"
              >
                {content.requestTrial}
              </Button>
            </>
          ) : (
            <div
              ref={dropdownRef}
              className="flex justify-end items-center gap-4 cursor-pointer w-fit"
              onClick={toggleDropdown}
            >
              <Typography
                block
                className="text-white text-xs md:text-base !font-medium"
              >
                {user.given_name}
              </Typography>
              <div className="relative">
                <FaUserCircle className="text-base md:text-3xl" />
                {isDropdownOpen && (
                  <Button
                    onClick={handleSignOut}
                    className="absolute mt-1 !h-7 shadow-lg right-0 rounded-sm z-50 !text-black"
                    loading={loading}
                  >
                    {content.logout}
                  </Button>
                )}
              </div>
            </div>
          )}
          <CgMenu
            color="white"
            size={20}
            className="lg:hidden"
            onClick={toggleIsOpen}
          />
        </div>
      </nav>
      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-40 bg-black opacity-70"
            onClick={toggleIsOpen}
          />
          <div className="fixed inset-y-0 left-0 z-50 w-[80%] transform bg-black text-white transition-transform duration-300 ease-in-out border-r border-r-white/20">
            <div className="w-full flex justify-end" onClick={toggleIsOpen}>
              <img src={Cross} alt="Cross" className="px-4 pt-2" />
            </div>
            <ul className="space-y-8 p-6">
              {navLink.map((item) => (
                <NavItem
                  key={item.title}
                  item={item}
                  pathname={pathname}
                  openModal={openModal}
                  setIsOpen={setIsOpen}
                />
              ))}
            </ul>
            {!isSignedIn ? (
              <Button
                variant="outline"
                onClick={() => openModal(NAV_LINKS_NAME.REQUEST_A_TRIAL)}
                className="mx-4 block font-medium sm:hidden text-base rounded-[20px] h-10"
              >
                {content.requestTrial}
              </Button>
            ) : null}
          </div>
        </>
      )}
      <div className="h-[1px] bg-gradient-to-r from-black/0 via-white/10 to-black/0" />
    </div>
  );
};

export default Header;
