import React from "react";
import { twMerge } from "tailwind-merge";
import Typography from "./Typography";

interface Props {
  text: string;
  className?: string;
}

const GradientText: React.FC<Props> = ({ text, className }) => {
  return (
    <Typography
      variant="title"
      block
      className={twMerge(
        "inline-block !font-medium md:text-6xl md:pb-2 pr-2 md:pr-3.5",
        className
      )}
      textGradient
    >
      {text}
    </Typography>
  );
};

export default GradientText;
