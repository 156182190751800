import React, { Fragment } from "react";
import Typography from "../../components/Typography";
import contentJSON from "../../constants/content.json";
import Markdown from "react-markdown";
import ReverseLines from "../../assets/svgs/ReverseLines.svg";
import BackgroundLines from "../../assets/svgs/BackgroundLines.svg";

const Privacy: React.FC = () => {
  const content = contentJSON.privacy;

  return (
    <>
      <div className="absolute inset-0 z-10 bg-about-lines-pattern bg-no-repeat -mt-24 2xl:w-[1700px] overflow-hidden" />
      <img src={ReverseLines} className="absolute bottom-0 right-0 top-[80%]" />
      <img
        src={ReverseLines}
        className="absolute left-0 top-[200%] md:hidden block w-full opacity-50"
      />
      <img
        src={BackgroundLines}
        className="absolute left-0 top-[500%] md:top-[300%]"
      />
      <div className="relative z-20 py-20 px-4">
        <div className="mx-auto w-full 2xl:max-w-[1700px] pb-28">
          <Typography
            variant="heading"
            block
            className="mb-5 text-center text-white !font-medium"
          >
            {content.title}
          </Typography>
          <div className="container mx-auto">
            <Typography
              block
              className="mx-auto !font-medium mt-6 text-white font-inter !text-2xl"
            >
              {content.statement}
            </Typography>
            <Typography
              block
              className="mx-auto text-white font-inter !font-normal !text-lg"
            >
              {content.lastUpdated}
            </Typography>
            <Markdown
              components={{
                a: ({ node, ...props }) => (
                  <a
                    {...props}
                    className="text-primary-blue underline hover:text-secondary-blue cursor-pointer"
                  />
                ),
              }}
              className="text-[#F5F5F5] font-inter mt-2 font-lg leading-8"
            >
              {content.description}
            </Markdown>
            <ol className="mt-6 space-y-10">
              {content.privacyPolicy.map((policy, index) => (
                <li key={index}>
                  <Typography
                    className="!font-medium !text-2xl font-inter !text-[#F5F5F5]"
                    block
                  >
                    {policy.heading}
                  </Typography>
                  <Typography
                    className="mt-3 !font-normal text-lg font-inter !text-[#F5F5F5]"
                    block
                  >
                    {policy.description}
                  </Typography>

                  <ol className="mt-2 space-y-2">
                    {policy.content?.map((item, policyiIndex) => (
                      <Fragment key={policyiIndex}>
                        {policyiIndex === 1 ? (
                          <Typography
                            className="mt-3 !text-[#F5F5F5] font-inter text-lg !font-normal"
                            block
                          >
                            {policy.description1}
                          </Typography>
                        ) : null}
                        <li className="list-decimal ml-8 text-[#F5F5F5]">
                          <Typography key={index} block>
                            <span className="!font-semibold font-inter text-lg text-[#F5F5F5]">
                              {item.subheading}:{" "}
                            </span>
                            <span className="text-[#F5F5F5] font-inter text-lg !font-normal">
                              {item.details}
                            </span>
                          </Typography>
                        </li>
                      </Fragment>
                    ))}
                  </ol>
                </li>
              ))}
            </ol>

            <div className="container mt-6 mx-auto">
              <Typography
                className="!font-medium !text-2xl font-inter !text-[#F5F5F5]"
                block
              >
                {content.contactUs.heading}
              </Typography>
              <Typography
                className="mt-3 !text-[#F5F5F5] text-lg font-inter !font-medium"
                block
              >
                {content.contactUs.details}
              </Typography>
            </div>
          </div>
        </div>
        <div className="border w-3/5 mx-auto" />
        <div
          className="mx-auto w-full 2xl:max-w-[1700px] pt-28"
          id="ccpaNotice"
        >
          <Typography
            variant="heading"
            block
            className="mb-5 text-center text-white !font-medium"
          >
            {content.ccpaNotice.title}
          </Typography>
          <div className="container mx-auto">
            <Typography
              block
              className="mx-auto -mt-2 font-inter !text-lg !font-normal text-center !text-[#F5F5F5]"
            >
              {content.ccpaNotice.lastUpdated}
            </Typography>
            <Typography
              block
              className="mx-auto mt-4 font-inter !font-normal !text-lg !text-[#F5F5F5]"
            >
              {content.ccpaNotice.description}
            </Typography>
            <Typography
              className="!font-semibold mt-4 font-inter !text-[#F5F5F5] !text-lg"
              block
            >
              {content.ccpaNotice.rights.title}
            </Typography>
            <Typography
              className="mt-3 !font-normal font-inter !text-[#F5F5F5] !text-lg"
              block
            >
              {content.ccpaNotice.rights.description}
            </Typography>
            <ol className="mt-6 space-y-10">
              {content.ccpaNotice.rights.rightsList.map((right, index) => (
                <li key={index} className="list-decimal ml-6 !text-[#F5F5F5]">
                  <Typography
                    className="!font-normal font-inter !text-[#F5F5F5] !text-lg"
                    block
                  >
                    <span className="!font-semibold font-inter !text-[#F5F5F5] !text-lg">
                      {" "}
                      {right.right}:{" "}
                    </span>{" "}
                    {right.description}
                  </Typography>
                  <ol className="mt-2">
                    {right.details?.map((detail, index) => (
                      <li
                        key={index}
                        className="text-[#F5F5F5] list-disc ml-10"
                      >
                        <Typography
                          className="!font-normal font-inter !text-[#F5F5F5] !text-lg"
                          block
                        >
                          {detail}
                        </Typography>
                      </li>
                    ))}
                  </ol>
                </li>
              ))}
            </ol>
            <div className="container mt-6 mx-auto">
              <Typography
                className="!font-semibold font-inter !text-[#F5F5F5] !text-lg"
                block
              >
                {content.exerciseYourRights.heading}
              </Typography>
              <Typography
                className="mt-3 !font-normal font-inter !text-[#F5F5F5] !text-lg"
                block
              >
                {content.exerciseYourRights.details}
              </Typography>
              <Typography
                className="mt-3 !font-normal font-inter !text-[#F5F5F5] !text-lg"
                block
              >
                {content.exerciseYourRights.endingNote}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
