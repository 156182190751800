import Typography from "../../Typography";
import React from "react";
import contentJSON from "../../../constants/content.json";
import InsightIconOne from "../../../assets/svgs/InsightIconOne.svg";
import InsightIconTwo from "../../../assets/svgs/InsightIconTwo.svg";
import Insights from "../../../assets/svgs/Insights.svg";
import InsightIconThree from "../../../assets/svgs/InsightIconThree.svg";
import ImageWithTextSection from "../../ImageWithTextSection";
import GradientText from "../../GradientText";
import Stats from "../../Stats";

const InsightSection = () => {
  const content = contentJSON.home.insightSection;


  return (
    <div
      className="relative mx-auto space-y-12 bg-lines-pattern bg-no-repeat overflow-hidden"
      id="insights"
    >
      <div className="bg-gradient-pattern h-full">
        <div className="relative z-10 mx-auto mb-[56px] w-10/12 text-center md:w-[62%] 2xl:w-[1500px]">
          <Typography
            variant="title"
            className="inline-block !font-medium text-white !text-2xl md:!text-[34.5px] 2xl:!text-5xl"
          >
            {content.desc1}
            <GradientText
              text={content.desc2}
              className="!text-2xl md:!text-[34.5px] md:py-2 2xl:!text-5xl"
            />
            {content.desc3}
            <GradientText
              text={content.desc4}
              className="!text-2xl md:!text-[34.5px] md:py-2 2xl:!text-5xl"
            />
          </Typography>
        </div>
        <div className="section-gradient section-gradient-right" />
        <div className="flex flex-col md:flex-row mx-auto gap-6 w-11/12 md:w-[62%] justify-between items-center md:items-baseline 2xl:w-[1500px]">
          <Stats data={content.stats[0]} />
          <Stats data={content.stats[1]} />
          <Stats data={content.stats[2]} />
        </div>
        <div className="relative flex flex-col lg:flex-row overflow-hidden justify-center items-center my-14">
          <div className="relative flex flex-col lg:flex-row overflow-hidden justify-center items-center">
            <div className="w-11/12 md:w-1/2 flex lg:justify-end justify-center items-center">
              <div className="mr-0 lg:mr-16 mb-16 lg:mb-0">
                <img src={Insights} alt="Insights" height={380} width={380} />
              </div>
            </div>
            <div className="w-11/12 md:w-3/4 lg:w-[55%]">
              <div className="lg:w-[78%] 2xl:w-full space-y-12">
                <ImageWithTextSection
                  icon={InsightIconOne}
                  data={content.sideOption[0]}
                />
                <ImageWithTextSection
                  icon={InsightIconTwo}
                  data={content.sideOption[1]}
                />
                <ImageWithTextSection
                  icon={InsightIconThree}
                  data={content.sideOption[2]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightSection;
