import React from "react";
import Modal from "./Modal";
import Input from "./Input";
import { useState } from "react";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { isValidEmail } from "../utils/common";
import NamedLogo from "../assets/svgs/namedLogo.svg";
import contentJSON from "../constants/content.json";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { useCookies } from "react-cookie";
import { PUBLIC_ROUTES } from "../routes";

interface ILoginModalProps {
  modalOpen: boolean;
  closeModal: () => void;
}

const LoginModal: React.FC<ILoginModalProps> = ({ modalOpen, closeModal }) => {
  const content = contentJSON.LoginModal;
  const navigate = useNavigate();
  const { setIsSignedIn } = useAuth();

  const [cookies, setCookie] = useCookies(["email"]);

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState(cookies.email);
  const [rememberUser, setRememberUser] = useState(false);
  const [isNewPassRequired, setIsNewPassRequired] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    let valid = true;
    const newErrors: { [key: string]: string } = {};
    if (!email.trim()) {
      newErrors.email = content.emailRequired;
      valid = false;
    } else if (!isValidEmail(email)) {
      newErrors.email = content.inValidPass;
      valid = false;
    }
    if (!password.trim()) {
      newErrors.password = content.passwordRequired;
      valid = false;
    } else {
      if (password.trim().length < 8) {
        newErrors.password = content.passLengthMessage;
        valid = false;
      }
      if (!/[a-z]/.test(password)) {
        newErrors.password = content.requiresLowercase;
        valid = false;
      }
      if (!/[A-Z]/.test(password)) {
        newErrors.password = content.requiresUppercase;
        valid = false;
      }
      if (!/[0-9]/.test(password)) {
        newErrors.password = content.requiresNumber;
        valid = false;
      }
      if (!/[\W_]/.test(password)) {
        newErrors.password = content.requiresSymbol;
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    setRememberUser(!rememberUser);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (errors.password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      setLoading(true);

      if (isNewPassRequired) {
        await handleNewPasswordSignIn();
      } else {
        await handleExistingUserSignIn();
      }
    } catch (error) {
      handleSignInError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordSignIn = async () => {
    const response = await confirmSignIn({ challengeResponse: password });

    if (response.nextStep.signInStep === "DONE") {
      completeSignIn(response.isSignedIn);
    }
  };

  const handleExistingUserSignIn = async () => {
    const response = await signIn({ username: email, password });

    if (
      response.nextStep.signInStep ===
      "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
    ) {
      setIsNewPassRequired(true);
      setPassword("");
    } else if (response.nextStep.signInStep === "DONE") {
      completeSignIn(response.isSignedIn);
    }
  };

  const completeSignIn = (isSignedIn: boolean) => {
    localStorage.setItem("isSignedIn", isSignedIn.toString());
    setIsSignedIn(true);
    closeModal();
    if (rememberUser) {
      setCookie("email", email);
    }
    navigate(PUBLIC_ROUTES.WEALTH_VISTA_SOLUTION);
  };

  const handleSignInError = (error: unknown) => {
    setEmail("");
    setPassword("");
    toast.error((error as Error).message);
  };

  return (
    <Modal isOpen={modalOpen} onClose={closeModal}>
      <div className="mt-5 mb-12">
        <img src={NamedLogo} alt="Logo" className="mx-auto w-40 lg:w-64" />
      </div>
      {isNewPassRequired ? (
        <h2 className="text-xl text-center font-bold text-white mb-5">
          {content.setNewPassword}
        </h2>
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-3">
          <Input
            type="text"
            placeholder={content.email}
            disabled={isNewPassRequired}
            value={email}
            onChange={handleEmailChange}
            error={errors.email}
          />
          <Input
            type="password"
            placeholder={
              isNewPassRequired ? content.newPassword : content.password
            }
            value={password}
            onChange={handlePasswordChange}
            error={errors.password}
          />
        </div>
        <div className="mb-5 flex justify-between px-2 text-13 text-white">
          <span>
            <Checkbox
              label={content.rememberMe}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </span>
        </div>
        <div className="flex w-full justify-center">
          <Button type="submit" variant="outline" loading={loading}>
            {isNewPassRequired ? content.confirmPassword : content.signIn}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default LoginModal;
